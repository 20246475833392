import axios from "axios";
import { Message, MessageBox } from "element-ui";
import store from "@/store";
import FingerprintJS from "fingerprintjs2";
import Cookies from "js-cookie"; // 导入 js-cookie
import router from "@/router"; // 引入路由实例
// import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // 请求的基础URL
  timeout: 5000, // 请求超时时间
});
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 对应国际化资源文件后缀
axios.defaults.headers["Content-Language"] = "zh_CN";
function getFingerprint() {
  return new Promise((resolve, reject) => {
    FingerprintJS.get((components) => {
      const values = components.map((component) => component.value);
      const fingerprint = FingerprintJS.x64hash128(values.join(""), 31);
      resolve(fingerprint);
      reject("");
    });
  });
}
// 请求拦截器
service.interceptors.request.use(
  async (config) => {
    try {
      // 生成指纹并设置到请求头
      config.headers["Yacoo-FP"] = await getFingerprint();
      // 检查本地存储中的授权token并设置到请求头
      const authToken = Cookies.get("authToken");
      if (authToken) {
        config.headers["Authorization"] = authToken;
      }
      // 动态设置超时时间
      if (config.isUpload) {
        config.timeout = 20000; // 文件上传接口超时时间设置为8秒
      } else {
        config.timeout = 5000; // 默认超时时间5秒
      }
    } catch (error) {
      console.error("Error generating fingerprint:", error);
      return Promise.reject(error);
    }

    return config;
  },
  (error) => {
    console.error("request error", error);
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data;

    // 处理非200或10001的情况
    if (res.code !== 200 && res.code !== 100003) {
      if (res.code === 401) {
        Cookies.remove("authToken");
        localStorage.removeItem("userInfo");
        router.push("/login", () => {});
        // 跳转到登录页
        return {
          code: 401,
          message: "未授权，请重新登录",
        };
      } else if (res.code === 500) {
        Message({
          message: res.message,
          type: "error",
        });
        // 返回处理后的错误信息，但不影响后续代码
        return Promise.reject(new Error(res.message));
      } else if (res.code === 100001) {
        Message({
          message: res.message,
          type: "error",
        });
        // 返回处理后的错误信息，但不影响后续代码
        return Promise.reject(new Error(res.message));
      } else {
        Message({
          message: res.message,
          type: "error",
        });
        // 返回一个特定的响应
        return { code: res.code, message: res.message };
      }
    } else {
      // 正常返回数据
      return res;
    }
  },
  (error) => {
    console.error("response error", error);
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    // 返回错误，但不中断后续代码执行
    return { code: -1, message: error.message };
  }
);

export default service;
